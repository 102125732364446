<template>
  <div>
    <a-layout class="yj-conten-div">
      <a-layout-content>
        <div
          class="yj-conten"
          style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)"
        >
          <a-row class="yj-form">
            <a-col :span="20">
              <label>创建时间 :</label>
              <a-range-picker style="width:260px;" :value="Time" @change="handleChange" />
              <a-button type="primary" @click="getTableData">查询</a-button>
            </a-col>
            <a-col :span="4" align="right" style="margin-top:-20px;">
              <a-button icon="plus" type="primary" @click="Detail(0,0)">增加物料</a-button>
              <!--<a-button type="primary" class="margin-r" ghost @click="exportdata">导出</a-button>-->
            </a-col>
          </a-row>
          <a-table
            bordered
            :columns="columns"
            rowKey="ID"
            :data-source="tableData"
            :pagination="pagination"
            @change="pagerChange"
            class="yj-table-img"
          >
            <span slot="action" slot-scope="text, record">
              <a v-if="record.PrintStatus==0" @click="Detail(record.ID,0)">编辑</a>
              <a v-if="record.PrintStatus!=0" @click="Detail(record.ID,1)">详情</a>
              <a-divider v-if="record.PrintStatus==1" type="vertical" />
              <a-divider v-if="record.PrintStatus==0" type="vertical" />
              <a v-if="record.PrintStatus==0" @click="exportdata(record)">导出</a>
              <a v-if="record.PrintStatus!=0" @click="showWinningDetail(record)">明细</a>
              <a-divider v-if="record.PrintStatus==1" type="vertical" />
              <a v-if="record.PrintStatus!=0" @click="getProintData(record)">大数据</a>
              <a-divider v-if="record.BindType==2" type="vertical" />
              <a v-if="record.BindType==2" @click="BindQRMaterial(record)">绑定素材</a>
              <a-divider v-if="record.BindType==1" type="vertical" />
              <a v-if="record.BindType==1" @click="LookMaterial(record)">素材</a>
            </span>
          </a-table>
        </div>
      </a-layout-content>
      <a-modal
        v-model="visible"
        :maskClosable="false"
        :closable="false"
        title="导出进度"
        width="20%"
        height="60px"
        footer
      >
        <div style="width: 100%; height: 60px;">
          <a-progress
            :percent="change"
            :stroke-color="{
                              '0%': '#afec91',
                              '100%': '#52c41a',
                            }"
            :stroke-width="18"
          />
        </div>
      </a-modal>
      <a-modal
        v-model="mapModelDisplay"
        @close="infoWindowClose"
        title="地图"
        width="70%"
        height="650px"
        footer
      >
        <div style="width: 100%; height: 650px;">
          <baidu-map
            class="bm-view"
            ak="E8f3c743837925ce120eb93417f478db"
            :center="center"
            :zoom="zoom"
            :scroll-wheel-zoom="true"
          >
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
            ></bm-geolocation>
            <div v-for="(item,index) in items" :key="index">
              <bm-marker
                :position="{lng:item.Lng,lat:item.Lat}"
                :dragging="true"
                @click="titleShow(item)"
              ></bm-marker>
            </div>
            <bm-info-window
              name="name"
              :show="mapshow"
              @close="infoWindowClose"
              @open="infoWindowOpen"
              :position="location"
              :width="0"
              :height="0"
            >
              <p>名称：{{Name}}</p>
              <p>电话：{{Phone}}</p>
              <p>地址：{{Address}}</p>
            </bm-info-window>
          </baidu-map>
        </div>
      </a-modal>
       <a-mode>
         <div id="img">

         </div>
       </a-mode>
    </a-layout>
  </div>
</template>
<script src="https://api.map.baidu.com/api?v=2.0&ak=E8f3c743837925ce120eb93417f478db"></script>
<script>
import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { BmMarker } from "vue-baidu-map";
import { BmGeolocation } from "vue-baidu-map";
import { BmInfoWindow } from "vue-baidu-map";
import { BMap } from "vue-baidu-map";
//import AProgress from “”
export default {
  name: "",
  components: {
    //组件
    BaiduMap,
    BmMarker,
    BmInfoWindow,
    BmGeolocation,
    BMap
  },
  data() {
    return {
      Time: [],
      BeginTime: "",
      EndTime: "",
      change: 1,
      tableData: [],
      StoreQRMaterialRecordID: "",
      Name: "xxxxx",
      Phone: "132xxxxxxx",
      Address: "",
      Lat: "",
      Lng: "",
      items: [],
      zoom: 11,
      IsetInterval: Object,
      address: "222",
      visible: false,
      mapshow: false,
      mapModelDisplay: false,
      titleDisplay: false,
      footer: false,
      center: { lng: 103.850855, lat: 36.07554 },
      location: { lng: 0, lat: 0 },
      pagination: {
        total: 0,
        defaultPageSize: 0,
        current: 1,
        showTotal: total => `共 ${total} 条数据`
      },
      MapAttribute: {
        show: false,
        points: [],
        contents: "",
        address: "",
        offset: {}
      },
      columns: [
        {
          title: "机构名称",
          width: 200,
          dataIndex: "CustomName"
        },
        {
          title: "物料数量",
          width: 200,
          dataIndex: "MCount"
        },
        {
          title:"物料类型",
          width:150,
          customRender:(text,item)=>{
            var rt;
            switch(item.UsingType){
              case 0:
                rt="海报";
                break;
              case 1:
                rt="宣传页";
                break;
            }
            return rt;
          }
        },
        {
          title:"二维码类型",
          width:150,
          customRender:(text,item)=>{
            var rt;
            switch(item.QRCodeType){
              case 0:
                rt="一物一码H5";
                break;
              case 1:
                rt="H5图层码";
                break;
              case 2:
                rt="一物一码小程序码";
                break;
              case 3:
                rt="小程序图层码";
              break;
            }
            return rt;
          }
        },
        {
          title: "创建时间",
          width: 250,
          customRender: (text, item) => {
            return util.TimeStamptoDateTime(
              item.InputTime,
              "yyyy-MM-dd hh:mm:ss"
            );
          }
        },
        {
          title: "操作",
          width: 300,
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  props: {
    //组件属性
  },
  methods: {
    //方法
    showWinningDetail: function(e) {
      var self = this;
      console.log(e);
      self.$router.push({
        name: "PromoteMaterial_Detail",
        query: { StartNum: e.StartNum, EndNum: e.EndNum }
      });
    },
    Detail(ID, disabled) {
      console.log(ID);
      var self = this;
      self.$router.push({
        name: "PromoteMaterial_Add",
        query: { ID: ID, disabled: disabled }
      });
    },
    handleChange(value) {
      var self = this;
      self.Time = value;
      if (value.length == 0) {
        self.BeginTime = "";
        self.EndTime = "";
      } else {
        self.BeginTime = util.formatDate(self.Time[0]._d);
        self.EndTime = util.formatDate(self.Time[1]._d);
      }
    },
    getTableData: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Promote/GetQRMaterialOrderList",
        data: {
          pageIndex: self.pagination.current,
          BeginDate: self.BeginTime,
          EndDate: self.EndTime
        },
        OnSuccess: function(data) {
          self.tableData = data.data.Data;
          self.pagination.total = data.data.Total;
          self.pagination.defaultPageSize = data.data.RowCount;
          self.pagination.current = data.data.PageIndex;
        }
      };
      http.Post(op);
    },
    pagerChange: function(item) {
      var self = this;
      self.pagination.current = item.current;
      self.getTableData();
    },
    //关闭窗体
    infoWindowClose() {
      this.mapshow = false;
    },
    infoWindowOpen() {
      this.mapshow = true;
    },
    BindQRMaterial:function(e){//物料绑定
      var self = this;
      self.$router.push({
        name: "PromoteMaterial_BindMaterial",
        query: { 
            ID:e.ID,
            StartNum:e.StartNum,
            EndNum:e.EndNum
        }
      });
    },
    getProintData(e) {
      var self = this;
      self.infoWindowClose();
      var position = {};
      self.StartNum = e.StartNum;
      self.EndNum = e.EndNum;
      var op = {
        url: "/MarketingModule/Promote/GetlocationList",
        data: {
          StartNum: self.StartNum,
          EndNum: self.EndNum
        },
        OnSuccess: function(data) {
          self.items = [];
          if (data.data.length != 0) {
            self.mapModelDisplay = true;
            for (var i = 0; i < data.data.length; i++) {
              if (data.data[i].Lng != 0 && data.data[i].Lat != 0) {
                let name = data.data[i].BindName;
                let address = data.data[i].BindAddress;
                let phone = data.data[i].BindPhone;
                position = {
                  Num: i,
                  Lng: data.data[i].Lng,
                  Lat: data.data[i].Lat,
                  Name: name != null ? name : `名称：这是第${i}个点`,
                  Address: address != null ? address : `地址：这是第${i}个点`,
                  Phone: phone != null ? phone : `手机：这是第${i}个点`
                };
                self.items.push(position);
              }
            }
            self.center = self.location = {
              lng: self.items[0].Lng,
              lat: self.items[0].Lat
            };
          } 
          else {
            self.$message.warning("该物料未激活！", 0.5);
          }
        }
      };
      http.Post(op);
    },
    titleShow(e) {
      var self = this;
      self.infoWindowClose();
      self.Name = e.Name;
      self.Phone = e.Phone;
      self.Address = e.Address;
      self.location = { lng: e.Lng, lat: e.Lat };
      self.infoWindowOpen();
    },
    mapReady({ BMap }) {
      var self = this;
      self.gec = new BMap.Geocoder();
      self.Lng = self.items[0].Lng;
      self.Lat = self.items[0].Lat;
    },
    exportdata: function(e) {
      //导出数据触发事件
      var self = this;
      self.change=1;
      self.Insert_StoreQRMaterialRecord(e);
    },
    Insert_StoreQRMaterialRecord: function(e) {
      //插入导出物料进度记录表数据
      var self = this;
       self.visible=true;
      self.progress = 1;
      var op = {
        url: "/MarketingModule/Promote/Insert_StoreQRMaterialRecord",
        data: {
          MCount: e.MCount
        },
        OnSuccess: function(data) {
          console.log("进度记录表数据："+ data.data);
          self.StoreQRMaterialRecordID = data.data;
          self.Insertdata(e);
          self.IsetInterval = setInterval(() => {
            self.GetProgress(e);
          }, 2000);
        }
      };
      http.Post(op);
    },
    Insertdata: function(e) {
      //生成物料信息以及互动信息表数据
      var self = this;
      var op = {
        url: "/MarketingModule/Promote/ExportQrMaterialInfo",
        data: {
          MaterialOrderID: e.ID,
          MCount: e.MCount,
          UsingType: e.UsingType,
          StoreQRMaterialRecordID: self.StoreQRMaterialRecordID,
          QRCodeType:e.QRCodeType
        },
        OnSuccess: function(data) {

        }
      };
      http.Post(op);
    },
    exportExcle: function(e) {
      //导出数据Excel
      var op = {
        url: "/MarketingModule/Promote/GetQrMaterialInfoExcel",
        data: {
          MaterialOrderID: e.ID,
          isOffline: false //正常导出
        }, //下载接口参数
        FileName: "物料.xlsx", //下载文件重命名
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //文件Mine类型
      };
      http.DownLoadFile(op);
    },
    GetProgress(e) {
      var self = this;
      self.visible = true;
      var op = {
        url: "/MarketingModule/Promote/Get_Store_QRMaterialCount",
        data: {
          ID: self.StoreQRMaterialRecordID,
          MCount: e.MCount
        },
        OnSuccess: function(data) {
          var progress = (parseInt(data.data) / parseInt(e.MCount)) * 100;
          if(progress > self.change)
            self.change = parseInt(progress);
          if (self.change >= 100) {
            clearInterval(self.IsetInterval);
            self.exportExcle(e);
            setTimeout(function() {
              self.$message.success("导出成功！");
              self.visible = false;
            }, 800);
            self.getTableData();
          }
        }
      };
      http.Post(op);
    },
    LookMaterial:function(e){
      var self=this;
      localStorage.setItem("ID",e.MaterialID);
      localStorage.setItem("Action","查看")
      self.$router.push({
            name: "PromoteMaterial_MaterialDetail"})
    },
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },

  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    this.getTableData();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>
<style scoped>
.bm-view {
  width: 100%;
  height: 100%;
}

.ant-modal {
  margin-top: -155px;
  padding-top: -155px;
}
</style>
